import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';

import Button from '../components/button';
import Single from '../components/single';

export default function NotFound() {
	// Opinionated: do not record an exception in Sentry for 404
	return (
		<Single>
			<Head>
				<title>Página no Encontrada - Bendo.cl</title>
			</Head>
			<Image src="/3d.svg" width={120} height={83} />
			<h1>404</h1>
			<p>
				¡Ups! No encontramos la página a la que quieres ingresar... ¿Por qué
				mejor no te pones a vender?
			</p>
			<Link href="/create">
				<Button primary>Crea tu catálogo</Button>
			</Link>
		</Single>
	);
}
